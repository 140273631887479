import React from "react"
import PrivacyPolicyTemplate from "../pagesTemplates/privacy-policy"
import gatsbyi18Context from "../contexts/gatsby-i18n-context.js"

export default (props) => {
  const {
    data: { page },
    pageContext,
  } = props
  const { langKey: language } = pageContext

  const data = {
    language,
  }

  return (
    <gatsbyi18Context.Provider value={data}>
      <PrivacyPolicyTemplate {...props} data={page} />
    </gatsbyi18Context.Provider>
  )
}

export const query = graphql`
  query {
    page: wordpressPage(slug: { eq: "privacy-policy" }) {
      title
      content
    }
  }
`
